.Read_More {
    color: var(--primary);
    border: none;
    background-color: white;
    padding: 10px 20px 10px 20px;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
    width: 170px;
    justify-content: space-between;
}

.Read_More:hover {
    width: 220px;
    color: white;
    background-color: var(--primary);
}

.arrow_right {
    transition: 0.2s ease-in-out;
}

.Read_More:hover .arrow_right {
    transform: rotate(180deg);
    color: white;
    fill: white !important;

}

.Read_More:hover .arrow_right path {
    fill: white !important;
}

.Read_More_2 {
    color: white;
    border: none;
    background-color: var(--primary);
    padding: 10px 20px 10px 20px;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
    width: 170px;
    justify-content: space-between;
}

.Read_More_2:hover {
    width: 220px;
    color: var(--primary);
    background-color: white;
}

.arrow_right_2 {
    transition: 0.2s ease-in-out;
    fill: white;
    color: white;
}

.arrow_right_2 path {
    fill: white;
}

.Read_More_2:hover .arrow_right_2 {
    transform: rotate(180deg);
    color: white;
    fill: white !important;

}

.Read_More_2:hover .arrow_right_2 path {
    fill: var(--primary) !important;
}