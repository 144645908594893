input {
    background-color: rgba(0, 0, 0, 0.075);
    border: none;
    height: 50px;
}

label {
    color: var(--primary);
    font-size: var(--Regular);
}

textarea {
    background-color: rgba(0, 0, 0, 0.075);
    border: none;
    padding: 10px;
}
.file-upload-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #1B7A99; /* Match the color of the SVG */
}

.file-upload-input {
    display: none;
}