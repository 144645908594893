.Hover2 {
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    color: var(--primary);
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.Hover2:hover {
    background-color: var(--primary);
    color: white;
    transform: scale(1.02);
}