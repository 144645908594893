.Button {
    color: white;
    border: none;
    background-color: var(--primary);
    padding: 10px 20px 10px 20px;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.Button:hover {
    transform: scale(1.04);
}

.Nav {
    transition: 0.2s ease-in-out;
}

.Nav:hover {
    transform: scale(1.04);
}

.SubNav {
    display: none !important;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.Nav.active ~ .SubNav{
    display: block !important;
    opacity: 1;
}

.Mobile {
    pointer-events: none;
    background-color: white;
    height: 0px;
    position: absolute;
    left: 0;
    z-index: -10;
    opacity: 0;
    top: 150px;
    transition: 0.3s;
}

.Mobile.active {
    height: auto;
    pointer-events: all;
    background-color: white;
    opacity: 1;
    right: 0;
    z-index: 10;
    position: absolute;
    left: 0;
    top: 150px;
}