.swiper-button-next {
    top: 25px !important;
    right: 10% !important;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2230%22%20height%3D%2227%22%20viewBox%3D%220%200%2030%2027%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2.0125%2012.4534C1.37894%2012.5491%200.890625%2013.1537%200.890625%2013.8853C0.890625%2014.6835%201.47176%2015.3305%202.18864%2015.3305L25.0047%2015.3305L16.7627%2024.4698L16.6367%2024.6316C16.2586%2025.1965%2016.299%2025.9995%2016.7589%2026.5136C17.2647%2027.0791%2018.0866%2027.081%2018.5945%2026.5178L29.0465%2014.9292C29.1098%2014.8617%2029.167%2014.7871%2029.217%2014.7067C29.5682%2014.1426%2029.5176%2013.3621%2029.0651%2012.8605L18.5945%201.25317L18.4486%201.11359C17.9396%200.69504%2017.2186%200.743412%2016.7588%201.25757C16.253%201.82314%2016.2547%202.73815%2016.7627%203.3013L25.0068%2012.4402L2.18864%2012.4402L2.0125%2012.4534Z%22%20fill%3D%22%23021633%22%2F%3E%3C%2Fsvg%3E');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 27px;


}

.swiper-button-next::after {
    content: none;


}

.swiper-button-prev::after {
    content: none;
}

.swiper-button-prev {
    top: 25px;
    left: 81% !important;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2230%22%20height%3D%2227%22%20viewBox%3D%220%200%2030%2027%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2.0125%2012.4534C1.37894%2012.5491%200.890625%2013.1537%200.890625%2013.8853C0.890625%2014.6835%201.47176%2015.3305%202.18864%2015.3305L25.0047%2015.3305L16.7627%2024.4698L16.6367%2024.6316C16.2586%2025.1965%2016.299%2025.9995%2016.7589%2026.5136C17.2647%2027.0791%2018.0866%2027.081%2018.5945%2026.5178L29.0465%2014.9292C29.1098%2014.8617%2029.167%2014.7871%2029.217%2014.7067C29.5682%2014.1426%2029.5176%2013.3621%2029.0651%2012.8605L18.5945%201.25317L18.4486%201.11359C17.9396%200.69504%2017.2186%200.743412%2016.7588%201.25757C16.253%201.82314%2016.2547%202.73815%2016.7627%203.3013L25.0068%2012.4402L2.18864%2012.4402L2.0125%2012.4534Z%22%20fill%3D%22%23021633%22%2F%3E%3C%2Fsvg%3E');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
    width: 30px;
    height: 27px;

}

.Slider_Hover {
    background-color: rgba(255, 255, 255, 0.7);
    position: relative;
    right: 200px;
    border-radius: 10px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.349);
    padding: 10px 0px;
}

@media (max-width:500px) {
    .Slider_Hover {
        display: flex;
        align-items: center;
        justify-content: center;
        right: -10px;
        top: 0;
        box-shadow: none;
        padding: 40px 20px;
        width: 100%;
    }

    .swiper-button-prev {
        top: 25px;
        left: 60% !important;


    }
}