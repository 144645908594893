.swiper-slide {
    opacity: 1;
}

.swiper-slide-active {
    opacity: 1;
    
}

.Margin {
    margin-top: -10rem !important;
}

.swiper-button-next {
    position: absolute !important;
    bottom: 100px !important;

}

@media (max-width: 500px) {
    .Margin {
        margin-top: 0rem !important;
    }
}