* {
  font-family: "Montserrat", sans-serif;

}

:root {
  --primary: #1B7A99;
  --secondary: #A1BFCC;
  --grey: #737373;

  --navy: #021633;
  --subTitle: 40px;
  --Title: 70px;
  --Title2: 65px;
  --Regular: 20px;
}

.Location {
  border-radius: 10px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 10px 0px;
  font-size: var(--Regular);

}

.Country {
  font-size: 12px;
  font-weight: lighter;
}

.Main {
  height: 85vh;
}


.HeroHome {
  height: 91vh;
}

.Hover {
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.Hover:hover {
  transform: scale(1.02);
}

.Sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 20%;
}

.service_Image {
  border-radius: 10px;

  top: 0;
}

@media (max-width: 500px) {
  :root {

    --subTitle: 20px;
    --Title: 35px;
    --Title2: 28px;
    --Regular: 12px;
  }

  .Country {
    font-size: 10px !important;

  }

  .HeroHome {
    height: 50vh;
  }

  .Main {
    height: 50vh;
  }
}