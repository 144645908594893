.map-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

}

.map-image {
    width: 100%;

    display: block;
}

.map-pin {
    position: absolute;
    width: 21px;
    height: 29px;
    transform: translate(-50%, -50%) !important;
    cursor: pointer;
    transition: transform 0.2s;
}

.country {
    font-size: 10px;
    display: none;
    transition: 0.2s ease-in-out;
    margin-top: -20px;
}

.map-pin:hover {
    transform: translate(-50%, -50%) scale(1.5) !important;

}



.map-pin:hover .country {
    display: block !important;

}

@media (max-width: 1440px) {
    .map-pin {

        transform: translate(-35%, -50%) !important;

    }
}

@media (max-width: 1024px) {
    .map-pin {

        transform: translate(-25%, -40%) !important;

    }
}

@media (max-width: 600px) {
    .map-pin {

        width: 6px;
        height: 29px;


    }

    .map-image {
        transform: scale(1);
    }

    .country {
        margin-top: 0px;
        font-size: 4px !important;
        margin-right: 10px;
    }
}